<template>
  <div class="choiceData">
    <!-- <label @click="buttonProjectManagerFn" class="choiceDataBtn"> -->
    <label @click="dlgVisible = true" class="choiceDataBtn">
      <slot name="button"> </slot>
    </label>
    <el-dialog
      class="choiceDataCon"
      title="请选择"
      :visible.sync="dlgVisible"
      :append-to-body="true"
      width="60%"
    >
      <div class="selected-list">
        <div class="mainList">
          <div class="mainList_content">
            <div class="mainList_content_sub">
              <div class="mainList_operation_search" style="margin-bottom: 8px">
                <span style="margin-right: 8px"
                  >项目名称:
                  <el-select
                    v-model="filter.projectName"
                    clearable
                    filterable
                    placeholder="请选择项目名称"
                    class="ipt_width"
                    style="width: 60%"
                  >
                    <el-option
                      v-for="item in projectList"
                      :key="item.id"
                      :label="item.projectName"
                      :value="item.projectName"
                    >
                    </el-option>
                  </el-select>
                </span>

                <el-button
                  type="primary"
                  size="medium"
                  plain
                  icon="el-icon-search"
                  @click="pageChangeHandler(1)"
                  >搜索</el-button
                >
              </div>
              <el-table
                ref="multipleTable"
                :border="true"
                :data="selectedList"
                tooltip-effect="dark"
                height="string"
                v-loading="selectedListLoad"
              >
                <slot name="tableColumn-left"></slot>

                <el-table-column label="操作" width="100" align="center">
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      size="mini"
                      @click="
                        $emit('choiceDataChange', scope.row)
                        dlgVisible = false
                      "
                      :disabled="scope.row.id == projectSelsectId"
                      >{{ scope.row.id == projectSelsectId ? '已选' : '选择' }}
                    </el-button>
                  </template>
                </el-table-column>
                <slot name="tableColumn"></slot>
              </el-table>
              <el-pagination
                @current-change="pageChangeHandler"
                @size-change="handleSizeChange"
                :current-page="filter.pageNow"
                :page-size="filter.pageSize"
                prev-text="上一页"
                next-text="下一页"
                :total="filter.total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" name="footer" class="dialog-footer">
        <el-button type="info" plain @click="dlgVisible = false">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ChoiceProject',
  components: {},
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    projectSelsectId: {
      type: String,
      default: '',
    },
    applyType: {
      type: String,
      default: '',
    },
    invoiceExpenseType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dlgVisible: false,
      selectedListLoad: false,
      selectedList: [],
      filter: {
        pageNow: 1,
        total: 1,
        pageSize: 50,
        projectName: null,
      },

      projectList: [],
    }
  },
  computed: {
    ...mapState({
      projectInformation: state => state.project.projectInformation,
    }),
  },
  watch: {
    options: {
      deep: true,
      immediate: true,
      handler: function (val) {},
    },
    dlgVisible(val) {
      if (val) {
        this.getProject()
      } else {
        this.filter = {
          pageNow: 1,
          total: 1,
          pageSize: 50,
          projectName: null,
        }
      }
    },
  },
  created() {
    if (
      this.applyType == 'APPROVED_CONTRACT_PENDING' ||
      this.applyType == 'APPROVED_NO_CONTRACT_NEEDED'
    ) {
      this.$api.project
        .listUncontractedProject({ pageSize: 10000, pageNow: 1 })
        .then(res => {
          this.projectList = res?.data?.records
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      let obj = {
        pageNow: 1,
        pageSize: 10000,
      }
      if (this.invoiceExpenseType) {
        obj.invoiceExpenseType = this.invoiceExpenseType
      }
      this.$api.project
        .listProjectAgreement(obj)
        .then(res => {
          this.projectList = res?.data?.records
        })
        .catch(err => {
          console.log(err)
        })
    }
  },
  methods: {
    async getProject() {
      this.selectedListLoad = true
      if (
        this.applyType == 'APPROVED_CONTRACT_PENDING' ||
        this.applyType == 'APPROVED_NO_CONTRACT_NEEDED'
      ) {
        const res = await this.$api.project.listUncontractedProject(this.filter)
        this.selectedList = res?.data?.records
        this.filter.total = res?.data?.total
      } else {
        if (this.invoiceExpenseType) {
          this.filter.invoiceExpenseType = this.invoiceExpenseType
        }
        const res = await this.$api.project.listProjectAgreement(this.filter)
        this.selectedList = res?.data?.records
        this.filter.total = res?.data?.total
      }
      this.selectedListLoad = false
    },
    pageChangeHandler(val) {
      this.filter.pageNow = val
      this.getProject()
    },
    handleSizeChange(val) {
      this.filter.pageSize = val
      this.filter.pageNow = 1
      this.getProject()
    },
  },
}
</script>
<style scoped lang="scss"></style>
